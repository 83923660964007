import { debounce } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import '../../atoms/image/Image';
import type Image from '../../atoms/image/Image';
import './SlidesCarousel.pcss';

export enum SlidesCarouselEvent {
    SLIDE_CHANGED = 'slides-carousel-slide-changed'
}

class SlidesCarousel extends HTMLElement {
    #activeSlideIndex = 0;

    #slides: HTMLElement[] = [];

    #slidesElement: HTMLElement | null = null;

    #scrollHandler = this.#determineCurrentSlide.bind(this);

    #loadNextImage = false;

    set loadNextImage(value: boolean) {
        this.#loadNextImage = value;
    }

    public constructor() {
        super();
        const shadow = this.attachShadow({ mode: 'open' });
        shadow.innerHTML = `
            <style>#slides::-webkit-scrollbar { display: none; }</style>
            <div id="slides" part="slides">
                <slot name="slides-fill-start"></slot>
                <slot part="slide" name="slide"></slot>
                <slot name="slides-fill-end"></slot>
            </div>
        `;

        if (this.hasAttribute('load-next-image')) {
            this.loadNextImage = true;
        }
    }

    public next(skipCount = 0): void {
        this.#scroll(1, skipCount);
    }

    public prev(skipCount = 0): void {
        this.#scroll(-1, skipCount);
    }

    public get slide(): number {
        return this.#activeSlideIndex;
    }

    public scrollToSlide(slide: number, skipCount = 0, animate = true): void {
        let nextSlide = slide;
        const last = this.#slides.length - 1;

        if (slide > last) {
            nextSlide = last;
        }

        if (slide < 0) {
            nextSlide = 0;
        }

        if (nextSlide === this.slide) return;

        this.#scroll(nextSlide - this.#activeSlideIndex, skipCount, animate);
    }

    protected connectedCallback(): void {
        if (!this.shadowRoot) return;

        this.#slidesElement = this.shadowRoot.querySelector('[part="slides"]');

        this.shadowRoot.addEventListener('slotchange', (event) => {
            const slot = event.target as HTMLSlotElement;

            if (slot.name !== 'slide') return;

            this.#slides = slot.assignedElements() as HTMLElement[];

            if (this.#slides.length === 0) return;

            this.#slides[0].toggleAttribute('active', true);

            this.#determineCurrentSlide();
        });

        this.#slidesElement?.addEventListener('scroll', this.#scrollHandler);
    }

    protected disconnectedCallback(): void {
        this.#slidesElement?.removeEventListener('scroll', this.#scrollHandler);
    }

    #scroll(count: number, skipCount = 0, animate = true): void {
        if (!this.#slidesElement || this.#slides.length === 0) return;

        const last = this.#slides.length - 1;
        let next = this.#activeSlideIndex + count;

        if (next < 0) {
            next = last;
        }

        if (next > last) {
            next = 0;
        }

        if (this.#slides[next] === undefined) {
            return;
        }

        this.#activeSlideIndex = next;

        const offsetSlideIndex = next - skipCount;
        const slide = this.#slides[offsetSlideIndex];
        const offset = slide === undefined ? 0 : slide.offsetLeft;

        this.#slides.forEach((item, index) => {
            const setActive = index === this.#activeSlideIndex;

            item.toggleAttribute('active', setActive);

            if (setActive) {
                this.#handleScrolledIntoView(index);
            }
        });

        const timeout = debounce(() => {
            this.#slidesElement?.addEventListener('scroll', this.#scrollHandler);
            this.#slidesElement?.removeEventListener('scroll', timeout);
        }, 250);

        this.#slidesElement?.removeEventListener('scroll', this.#scrollHandler);
        this.#slidesElement?.addEventListener('scroll', timeout);

        if (this.#slidesElement && typeof this.#slidesElement.scrollTo === 'function') {
            this.#slidesElement.scrollTo({ left: offset, behavior: animate ? 'smooth' : 'auto' });
        }

        this.dispatchEvent(
            new CustomEvent(SlidesCarouselEvent.SLIDE_CHANGED, {
                detail: {
                    index: next
                }
            })
        );
    }

    #determineCurrentSlide(): void {
        if (!this.#slidesElement) return;

        for (let i = 0; i < this.#slides.length; i++) {
            if (this.#activeSlideIndex === i) {
                this.#handleScrolledIntoView(i);
                continue;
            }

            if (this.#isCurrentSlide(i)) {
                this.#activeSlideIndex = i;
                this.dispatchEvent(new Event('changed'));
                this.#handleScrolledIntoView(i);
                return;
            }
        }
    }

    #handleScrolledIntoView(index: number): void {
        const lastIndex = this.#slides.length - 1;

        const prevSlide = this.#slides[index === 0 ? lastIndex : index - 1];
        const slide = this.#slides[index] as HTMLSlotElement;
        const nextSlide = this.#slides[index === lastIndex ? 0 : index + 1];

        const current = slide.querySelector('img') as Image | null;

        this.#loadImage(current);

        if (this.#loadNextImage) {
            [prevSlide, nextSlide].forEach((slideElement) => {
                const image = slideElement.querySelector('img') as Image | null;
                this.#loadImage(image);
            });
        }
    }

    #loadImage(image: Image | null): void {
        if (
            image === null ||
            typeof image.loadSrc !== 'function' ||
            typeof image.loadSrcSet !== 'function'
        ) {
            return;
        }

        image.loadSrc();
        image.loadSrcSet();
    }

    #isCurrentSlide(index: number): boolean {
        if (!this.#slidesElement) return false;

        const gap = parseInt(getComputedStyle(this.#slidesElement).getPropertyValue('gap'), 10);

        const slide = this.#slides[index];
        const firstSlideRect = slide.getBoundingClientRect();
        const width = firstSlideRect.width;
        const start1 = this.#slidesElement.scrollLeft;
        const start2 = slide.offsetLeft;
        const end1 = start1 + width + gap;
        const end2 = start2 + width;

        const overlap = Math.max(0, Math.min(end1, end2) - Math.max(start1, start2) + 1);
        return overlap > width / 2;
    }
}

if (!customElements.get('nh-slides-carousel')) {
    customElements.define('nh-slides-carousel', SlidesCarousel);
}

export default SlidesCarousel;
