import type { DefaultComponentType } from '../../../../src/utils/types';
import './CarouselIndicators.pcss';

export enum CarouselIndicatorsVariants {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum CarouselIndicatorsEvents {
    SELECTED_INDICATOR = 'selected_indicator'
}

export type CarouselIndicatorsProps = DefaultComponentType & {
    indicators: number;
    activeIndex?: number;
    indicatorsInView?: number;
    variant?: CarouselIndicatorsVariants;
};

export default class CarouselIndicators extends HTMLElement {
    static get observedAttributes() {
        return ['active-index', 'indicators-in-view'];
    }

    get items(): NodeListOf<HTMLElement> {
        return this.querySelectorAll('[data-role="indicator"]');
    }

    get activeIndex(): number {
        return Number(this.getAttribute('active-index'));
    }

    set activeIndex(number: number) {
        const newIndex = this.#determineIndex(number);

        this.setAttribute('active-index', String(newIndex));
    }

    get indicatorsInView(): number {
        return Number(this.getAttribute('indicators-in-view'));
    }

    set indicatorsInView(number: number) {
        this.setAttribute('indicators-in-view', String(number));
    }

    get variant(): string | undefined {
        const variant = this.getAttribute('variant');
        if (variant === null) {
            return undefined;
        }

        const variants = Object.values<string>(CarouselIndicatorsVariants);
        return variants.includes(variant) ? variant : undefined;
    }

    set variant(value: string | undefined) {
        if (!value) {
            this.setAttribute('variant', CarouselIndicatorsVariants.DARK);
            return;
        }

        const variant = Object.values<string>(CarouselIndicatorsVariants);
        if (!variant.includes(value)) {
            this.setAttribute('variant', CarouselIndicatorsVariants.DARK);
            return;
        }

        this.setAttribute('variant', value);
    }

    public connectedCallback(): void {
        this.items.forEach((indicator) => {
            indicator.addEventListener('click', this.#handleIndicatorClick);
        });

        this.#scrollIndicatorInView(this.activeIndex);
        this.addEventListener(
            CarouselIndicatorsEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicatorEvent
        );
    }

    public disconnectedCallback(): void {
        this.items.forEach((indicator) => {
            indicator.removeEventListener('click', this.#handleIndicatorClick);
        });

        this.removeEventListener(
            CarouselIndicatorsEvents.SELECTED_INDICATOR,
            this.#handleSelectedIndicatorEvent
        );
    }

    protected attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        if (newValue !== oldValue) {
            if (name === 'active-index') {
                this.#activateIndicator(Number(newValue));
            }
        }

        if (name === 'indicators-in-view') {
            this.style.setProperty('--indicators-in-view', newValue);
        }
    }

    #handleIndicatorClick = (event: Event): void => {
        event.preventDefault();

        const indicatorElement = event.target as HTMLElement;
        this.activeIndex = Number(indicatorElement.dataset.index);
        this.dispatchEvent(
            new CustomEvent(CarouselIndicatorsEvents.SELECTED_INDICATOR, {
                detail: this.activeIndex,
                bubbles: true
            })
        );
    };

    #handleSelectedIndicatorEvent = (event: Event): void => {
        if (
            event instanceof CustomEvent &&
            event.type === CarouselIndicatorsEvents.SELECTED_INDICATOR
        ) {
            this.activeIndex = Number(event.detail);
        }
    };

    #activateIndicator(nextIndex: number): void {
        this.#scrollIndicatorInView(nextIndex);

        this.items.forEach((indicator, index) => {
            indicator.toggleAttribute('active', nextIndex === index);
        });
    }

    #determineIndex(index: number): number {
        let newIndex = index;
        const last = this.items.length - 1;

        if (index > last) {
            newIndex = 0;
        } else if (index < 0) {
            newIndex = last;
        }

        return newIndex;
    }

    #scrollIndicatorInView(index: number): void {
        if (this.items.length === 0) return;

        const next = this.#determineIndex(index);

        if (this.items[next] === undefined) {
            return;
        }

        const offsetSlideIndex = next - Math.floor(this.indicatorsInView / 2);
        const slide = this.items[offsetSlideIndex];
        const offset = slide === undefined ? 0 : slide.offsetLeft;

        if (this && typeof this.scrollTo === 'function') {
            this.scrollTo({ left: offset, behavior: 'smooth' });
        }
    }
}

if (!window.customElements.get('nh-carousel-indicators')) {
    window.customElements.define('nh-carousel-indicators', CarouselIndicators);
}
