import type { DefaultComponentType } from '../../../../src/utils/types';
import './Image.pcss';

export type ImageProps = DefaultComponentType & {
    src: string;
    alt: string;
    id?: string;
    srcset?: string;
    sizes?: string;
    width?: number;
    height?: number;
    objectFit?: 'cover' | 'contain' | 'fill' | 'scale-down' | 'none';
    container?: boolean;
    loading?: 'eager' | 'lazy';
};

export default class Image extends HTMLImageElement {
    constructor(props?: ImageProps) {
        super();

        if (props) {
            if (props.src) {
                this.src = props.src;
            }

            if (props.alt) {
                this.alt = props.alt;
            }

            if (props.id) {
                this.id = props.id;
            }

            if (props.srcset) {
                this.srcset = props.srcset;
            }

            if (props.width) {
                this.width = props.width;
            }

            if (props.height) {
                this.height = props.height;
            }

            if (props.objectFit) {
                this.style.objectFit = props.objectFit;
            }

            if (props.classes !== undefined) {
                props.classes.forEach((item) => {
                    this.classList.add(item);
                });
            }

            if (props.data !== undefined) {
                const data = props.data;
                Object.keys(data).forEach((key) => {
                    this.dataset[key] = data[key];
                });
            }
        }
    }

    connectedCallback(): void {
        this.addEventListener('load', this.#removeLoader.bind(this));

        if (this.complete) {
            this.#removeLoader();
        }
    }

    disconnectedCallback(): void {
        this.removeEventListener('load', this.#removeLoader.bind(this));
    }

    loadSrc(): void {
        if (!this.dataset.src || this.src === this.dataset.src) {
            return;
        }

        this.src = this.dataset.src;
        this.removeAttribute('data-src');
    }

    loadSrcSet(): void {
        if (!this.dataset.srcset || this.srcset === this.dataset.srcset) {
            return;
        }

        this.srcset = this.dataset.srcset;
        this.removeAttribute('data-srcset');
    }

    #removeLoader(): void {
        this.parentElement?.querySelector('.nh-loader')?.remove();
    }
}

if (!window.customElements.get('nh-image')) {
    window.customElements.define('nh-image', Image, { extends: 'img' });
}
